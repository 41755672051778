import type { ApiData, Metadata } from "./types";

import type { Route } from "nextjs-routes";

import compileValue from "./compileValue";
import getPageOgType from "./getPageOgType";
import * as templates from "./templates";
import { DEFAULT_DESCRIPTION_TEMPLATE } from "./templates/description";
import getThumbnail from "./templates/getThumbnail";

export default function generate<R extends Route>(
  route: R,
  apiData?: ApiData<R>
): Metadata {
  const params = {
    ...route.query,
    ...apiData,
  };

  const compiledTitle = compileValue(
    templates.title.make(route.pathname),
    params
  );
  const title = compiledTitle ? `PIT finance | ${compiledTitle}` : "";
  const description = compileValue(
    templates.description.make(route.pathname),
    params
  );

  const pageOgType = getPageOgType(route.pathname);

  return {
    title: title,
    description,
    thumbnail: getThumbnail(route.pathname),
    openGraph: {
      title: title,
      description:
        pageOgType !== "Regular page" ? DEFAULT_DESCRIPTION_TEMPLATE : "",
    },
  };
}
