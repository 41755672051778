import Head from "next/head";
import React from "react";

import type { Route } from "nextjs-routes";

import * as metadata from "lib/metadata";
import { useVault } from "@/hooks/vault/useVault";

import { NextSeo } from "next-seo";

type Props = Route;

const BuildHead = (props: Props) => {
  const {
    title: pTitle,
    description,
    openGraph,
    thumbnail,
  } = metadata.generate(props);
  // const { vaults } = useVault();
  // const vault =
  //   Object.values(vaults)?.find((v) => v.address === props?.query?.id) || null;
  let title = pTitle;
  // if (vault) {
  //   title = `PIT finance | ${vault?.token?.name} Vault`;
  // }
  const url = "https://pit.finance";
  const twitterTitle = "Pit finance | optimize yield farming on Sei blockchain";

  const favIconContent = (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/icons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/icons/favicon-16x16.png"
      />
      <link rel="manifest" href="/assets/icons/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/assets/icons/safari-pinned-tab.svg"
        color="#5bbad5"
      />
    </>
  );

  const ogContent = (
    <>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="Pit Finance" />
      <meta property="og:url" content={url} />
    </>
  );

  const twitterContent = (
    <>
      <meta
        property="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta property="twitter:url" name="twitter:url" content={url} />
      <meta
        property="twitter:title"
        name="twitter:title"
        content={twitterTitle}
      />
      <meta
        property="twitter:description"
        name="twitter:description"
        content={description}
      />
      <meta
        property="twitter:image"
        name="twitter:image"
        content="/assets/banner-twitter.png"
      />
      <meta
        property="twitter:og:image"
        name="twitter:og:image"
        content="/assets/banner-twitter.png"
      />
      <meta name="twitter:site" content="@pit.finance" />
      <meta name="twitter:creator" content="@pit.finance" />
    </>
  );

  const v1 = (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* OG TAGS */}
      <meta property="og:title" content={openGraph.title} />
      {openGraph.description && (
        <meta property="og:description" content={openGraph.description} />
      )}
      {favIconContent}
      {ogContent}
      {twitterContent}
    </Head>
  );

  const v2 = (
    <NextSeo
      title={title}
      description={description}
      canonical="https://www.pit.finance"
      openGraph={{
        url: "https://www.pit.finance",
        title: openGraph.title,
        description: openGraph.description,
        images: [
          {
            url: thumbnail,
            width: 800,
            height: 600,
            alt: "Og Image Alt",
            type: "image/jpeg",
          },
          {
            url: thumbnail,
            width: 900,
            height: 800,
            alt: "Og Image Alt Second",
            type: "image/jpeg",
          },
          { url: thumbnail },
          { url: thumbnail },
        ],
        siteName: "pit.finance",
      }}
      twitter={{
        handle: "@handle",
        site: "@site",
        cardType: "summary_large_image",
      }}
    />
  );

  return v1;
};

export default BuildHead;
