import type { Route } from "nextjs-routes";
import config from "configs/app";

export const DEFAULT_DESCRIPTION_TEMPLATE = config.meta_data.description;

// FIXME all page descriptions will be updated later
const TEMPLATE_MAP: Partial<Record<Route["pathname"], string>> = {};

export function make(pathname: Route["pathname"]) {
  const template = TEMPLATE_MAP[pathname];

  return template ?? DEFAULT_DESCRIPTION_TEMPLATE;
}
