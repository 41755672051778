import { AUDIT_REPORT_URL } from "@/types/home";
import { LayoutSectionCard } from "@/ui/Components/LayoutSectionCard";
import { Flex, Link, Text, Image } from "@chakra-ui/react";
import { FC } from "react";

export const Auditors: FC = () => {
  return (
    <LayoutSectionCard
      flexDirection="column"
      gap="60px"
      position="relative"
      zIndex={2}
      backgroundContent={
        <Image
          mx="auto"
          width="100%"
          height="100%"
          src="/images/auditor-decor.png"
          alt="auditor"
          position="absolute"
          bottom="-118px"
          display={{ base: "none", lg: "block" }}
          zIndex={1}
        />
      }
    >
      <Flex flexDirection="column">
        <Text size="5xl" textAlign="center">
          Auditors
        </Text>
        <Text size="3xl" textAlign="center">
          Security and risk management
        </Text>
      </Flex>
      <Flex flexDirection="column" gap="32px">
        <Link
          href="https://www.certik.com/"
          target="_blank"
          className="mx-auto"
        >
          <Image
            mx="auto"
            height="auto"
            src="/images/auditor.png"
            alt="auditor"
            className="w-[632px] h-auto mx-auto"
            width={{ base: "full", lg: "632px" }}
            border="2px solid"
            borderColor="neutral.stroke.light"
            boxShadow="0px 12px 32px 0px rgba(0, 0, 0, 0.12)"
            borderRadius="20px"
          />
        </Link>
        <Text size="xl" textAlign="center" maxW="632px" mx="auto">
          Verichains, a top security research firm, evaluated Pit Finance’s
          smart contract and found no critical issues. The report was made
          public on September 12, 2024.{" "}
          <Link href={AUDIT_REPORT_URL} textDecor="underline" target="_blank">
            Check the report for details.
          </Link>
        </Text>
      </Flex>
    </LayoutSectionCard>
  );
};
