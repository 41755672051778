import type { Route } from "nextjs-routes";

const TEMPLATE_MAP: Record<Route["pathname"], string> = {
  "/": "Amplify vaults - optimize yield farming on Sei blockchain",
  "/vaults": "Vaults",
  "/vaults/[id]": "Vault %id%",
  "/press-kit": "Press Kit",
  "/404": "404",
};

export function make(pathname: Route["pathname"]) {
  const template = TEMPLATE_MAP[pathname] ?? TEMPLATE_MAP["/"];
  return `${template}`;
}
