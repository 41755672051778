import { FC } from "react";
import { Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { SUPPORTED_TOKENS } from "@/types/home";
import { LayoutSectionCard } from "@/ui/Components/LayoutSectionCard";

const TokenInfoItem: FC<{
  title: string;
  image: string;
  color: string;
  textColor?: string;
}> = ({ image, title, color, textColor }) => (
  <Flex
    p="24px"
    flexDirection="column"
    height="200px"
    justifyContent="space-between"
    bgColor={color}
    className="p-[20px] rounded-[20px] border-solid border-[1px] border-dividerLight md:h-full h-auto bg-neutralLight01 flex flex-col gap-[42px]"
  >
    <Flex
      flexDirection="column"
      borderRadius="40px"
      padding="16px"
      height="64px"
      width="64px"
      bgColor="neutral.on.surface.1"
    >
      <Image src={image} alt="tokens" className="w-[32px] h-[32px]" />
    </Flex>
    <Text color={textColor ?? "neutral.text.button"} size="3xl">
      {title}
    </Text>
  </Flex>
);

export const SupportedTokens: FC = () => {
  return (
    <LayoutSectionCard>
      <Flex
        gap="24px"
        alignItems={{ xl: "center" }}
        justifyContent={{ xl: "space-between" }}
        flexDirection={{ base: "column", xl: "row" }}
        width="full"
      >
        <Text size="5xl" maxW={{ xl: "420px" }} lineHeight={{ xl: "52px" }}>
          Supported tokens
        </Text>
        <SimpleGrid
          columns={{ base: 2, md: 4 }}
          columnGap={{ base: "12px", md: "24px " }}
          rowGap={{ base: "12px", md: "24px " }}
          position="relative"
          w={{ base: "full", xl: "850px" }}
        >
          {SUPPORTED_TOKENS.map((item, index) => (
            <TokenInfoItem
              key={`${item.title}-${Math.random()}-${index}`}
              title={item.title}
              image={item.image}
              color={item.color}
              textColor={item.textColor}
            />
          ))}
        </SimpleGrid>
      </Flex>
    </LayoutSectionCard>
  );
};
