import { NextPage } from "next";
import BuildHead from "nextjs/BuildHead";
import PageNextJs from "nextjs/PageNextJs";
import { EarnWithPit } from "@/ui/Pages/Home/EarnWithPit";
import { AmplifyVaults } from "@/ui/Pages/Home/AmplifyVaults";
import { SupportedTokens } from "@/ui/Pages/Home/SupportedTokens";
import { Auditors } from "@/ui/Pages/Home/Auditors";

const Page: NextPage = () => {
  return (
    <PageNextJs pathname="/">
      <AmplifyVaults />
      <EarnWithPit />
      <SupportedTokens />
      <Auditors />
    </PageNextJs>
  );
};

Page.getHead = () => {
  return <BuildHead pathname="/"></BuildHead>;
};

export default Page;

export { base as getServerSideProps } from "nextjs/getServerSideProps";
