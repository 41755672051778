import { Route } from "nextjs-routes";

export default function getThumbnail(pathname: Route["pathname"]): string {
  switch (pathname) {
    case "/press-kit":
      return "/assets/og-press-kit-thumbnail.png";
    default:
      return "/assets/og-regular-thumbnail.png";
  }
}
