import React from "react";

import type { Route } from "nextjs-routes";

import { memo } from "react";

type Props = Route & {
  children?: React.ReactNode;
  address?: string;
};

const PageNextJs = (props: Props) => {
  return props.children;
};

export default memo(PageNextJs);
