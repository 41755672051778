import { APP_FEATURES } from "@/types/home";
import { LayoutSectionCard } from "@/ui/Components/LayoutSectionCard";
import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { FC } from "react";

const EarnWithPitInfoItem: FC<{
  index: number;
  title: string;
  description: string;
}> = ({ index, title, description }) => (
  <>
    <Flex gap="20px" alignItems="flex-start" w="full">
      <Flex
        height="36px"
        width="36px"
        padding="8px"
        gap="8px"
        borderRadius="40px"
        border="1px solid"
        borderColor="brand.primary"
        alignItems="center"
        justifyContent="center"
        fontSize="20px"
        fontWeight={700}
      >
        {index}
      </Flex>
      <Text
        size="3xl"
        w={{ lg: "70%", xl: "full" }}
        position="relative"
        top="-5px"
        style={{ wordWrap: "normal" }}
      >
        {title}
      </Text>
    </Flex>
    <Text size="xl">{description}</Text>
  </>
);

export const EarnWithPit: FC = () => {
  return (
    <LayoutSectionCard flexDirection="column">
      <Text size="5xl" textAlign="center">
        Earn with Pit
      </Text>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        columnGap="20px"
        rowGap="20px"
      >
        {APP_FEATURES.map((item, index) => (
          <GridItem
            colSpan={1}
            key={`${Math.random()}--${index}`}
            p="32px"
            gap="8px"
            display="flex"
            borderRadius="16px"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            minHeight={{ base: "240px", lg: "280px" }}
            bgColor="neutral.on.surface.1"
          >
            <EarnWithPitInfoItem
              index={index + 1}
              title={item.title}
              description={item.description}
            />
          </GridItem>
        ))}
      </Grid>
    </LayoutSectionCard>
  );
};
